<template>
    <q-layout view="hHh lpR fff">
        <q-header elevated>
            <HomeNav></HomeNav>
        </q-header>
        <q-page-container>
            <q-page>
                <div class="tw-py-5 max-container">
                    <slot></slot>
                </div>
            </q-page>
        </q-page-container>

        <q-footer class="footer">
            <HomeFooter></HomeFooter>
        </q-footer>
    </q-layout>
</template>

<script setup lang="ts">
import HomeNav from "~/components/HomeNav.vue"
import HomeFooter from "~/components/HomeFooter.vue"
import { onMounted } from "vue"
import { useMenuItemSelectedStore } from "~/stores/MenuItemSelected"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const menuItemSelectedStore = useMenuItemSelectedStore()
const shopCartStore = useShopCartStore()
const initSettingsStore = useDefaultSettingsStore()
shopCartStore.fetchShopCart()
const { get } = useOFetchCustom("init-settings")

/**
 *
 *  Life cicle events
 *
 *
 */

get(undefined, {
    onSuccess: (res) => {
        initSettingsStore.setSettings(res.data)
    },
})

onMounted(async () => {
    const route_name = router.currentRoute.value.name

    if (route_name) {
        menuItemSelectedStore.setByRoute(route_name.toString())
    }
})

/**
 *
 *  Functions section
 *
 *
 */
</script>

<style>
.shadown {
    box-shadow: 0px 4px 4px 0px rgba(6, 116, 191, 0.15);
}
.body-blur {
    filter: blur(3px);
}
</style>
